import restaurant from "./images/mi piace.jpg";
import img_card from "./images/1.png";
import imgheaderrestaurant from "./images/welcom.jpg";
import imgheaderrestaurantmb from "./images/welcom2.jpg";

import imgheader from "./images/header.webp";

import imgheaderm from "./images/headermb.webp";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/ring.webp";
import imgheadermiinimb from "./images/ring.webp";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Ivan",
        mireasa: "Cristina",
        data: "19 Octombrie 2024",
        data_confirmare: "15 septembrie 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "cristina.gamurar@gmail.com", 
       tel: "+37369345373",
       phone: "tel:+37369345373",
       viber: "viber://chat?number=%2B37369345373",
       whatsapp: "https://wa.me/+37369345373",
       messenger: "https://www.messenger.com/t/cristina.gamurar",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/xx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Mi Piace",
            data: "19 octombrie 2024, sâmbătă, ora 16:00",
            adress: "Strada Trandafirilor 4, Chișinău",
            harta: "https://maps.app.goo.gl/yxUEC3xtoEwKuTna6",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10884.499615254626!2d28.8579633!3d46.9985204!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97ea9742eac4d%3A0x677e28bd7061dda2!2sMiPiace!5e0!3m2!1sro!2s!4v1713162415602!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            img2: imgheaderrestaurantmb,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
           
            img: imgheadermiini,
            img2: imgheadermiinimb,
            title: "De aici începe totul...",
            message: "Dragostea vine întotdeauna din adâncurile sufletului, unește mereu oamenii și nu se împarte niciodată – Patty Sandy",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "",
            nasii_nume: "",
        }
    ],

}

export default data;